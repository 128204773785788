<template>
  <div id="ProfileProjectCard" :style="setWidthStyle">
    <div
      class="CardContent"
      :style="
        GuestMode == true || enoughCompetenceForJob
          ? 'padding-right: 32px;'
          : ''
      "
    >
      <span
        class="ComboSpanLeftAlign ProjectDifficultContainer ProjectTagHeader"
      >
        <span
          class="ProjectStage"
          :class="{
            IsWaiting: projectCardStatus?.s == 'yellow',
            IsDeclined: projectCardStatus?.s == 'red',
          }"
          >{{ projectCardStatus?.t }}</span
        >
        <span
          class="ProjectDifficult"
          v-if="
            Project.status !== 'moderation' && Project.status !== 'declined'
          "
        >
          <span
            v-for="index in getTaskDifficult(Project?.level)"
            :key="index"
            class="DifficultPainted"
          />
          <span
            v-for="index in 3 - getTaskDifficult(Project?.level)"
            :key="index"
            class="DifficultEmpty"
          />
        </span>
      </span>
      <span class="ProjectDesc" v-if="Project.status === 'moderation'">
        Проект отправлен на модерацию. О дальнейшем статусе проекта мы
        проинформируем тебя в почте и здесь:)
      </span>
      <span class="ProjectDesc" v-if="Project.status === 'declined'">
        Проект не прошёл модерацию. Необходимо внести изменения в проект.
        Комментарий модератора: {{ Project.comment }}
      </span>
      <p class="ProjectTitle" lang="ru">{{ project_data?.name }}</p>

      <div class="ProjectDirectionNew" v-if="Project.status !== 'moderation'">
        <span
          v-for="direction in project_data?.directions"
          :key="direction.text"
          class="ProjectDirection noselect"
          >{{ direction.text }}</span
        >
      </div>

      <div class="ProjectDesc">
        <span class="ql-editor shortDesc" v-html="project_data?.desc"></span>
      </div>

      <span class="ProjectInfoButton noselect" @click="showFullDesc = true"
        >Подробнее</span
      >

      <!-- <span
        class="ComboSpanLeftAlign ProjectDirectionsContainer ProjectCustomer"
      >
        <span
          v-if="project_data?.customer && project_data?.customer !== ''"
          class="ProjectDirectionText"
          >Заказчик: {{ project_data?.customer }}</span
        >
      </span> -->

      <!-- <span
        class="ComboSpanLeftAlign ProjectDirectionsContainer DirectionsList"
      >
        <span
          class="ProjectDirectionText"
          v-if="project_data?.directions.length > 0"
          >Направления</span
        >
        <span class="ProjectDirections">
          <span
            v-for="direction in project_data?.directions"
            :key="direction.text"
            class="ProjectDirection noselect"
            :style="backgroundStyle(direction.BGColor)"
            >{{ direction.text }}</span
          >
        </span>
      </span> -->

      <!-- <span class="ProjectTags">
        <span class="ProjectTag" v-for="tag in project_data?.tags" :key="tag">
          <span class="TagText">{{ tag.text }}</span>
          <span
            v-for="index in tag.difficult"
            :key="index"
            class="DifficultPainted"
          />
          <span
            v-for="index in 3 - tag.difficult"
            :key="index"
            class="DifficultEmpty"
          />
        </span>
      </span> -->

      <!-- <template v-if="GuestMode == false">
        <span class="UnfinishedTasks">{{
          `Невыполненные задачи: ${project_data.unfinishedTasks.length}`
        }}</span>
      </template> -->

      <!-- <template v-if="GuestMode == true">
        <span class="ComboSpanLeftAlign ProjectStageTimerContainer">
          <span class="ProjectStageTimer">{{ project_data.stageTimer }}</span>
        </span>
      </template> -->

      <div class="ProjectButtons">
        <template v-if="GuestMode == false">
          <!-- <ButtonGradientBorder ButtonText="Пригласить в команду" /> -->
          <!-- <a href="https://t.me/zhanna_levkina" target="_blank"
            ><ButtonGradientBorder ButtonText="Консультация" />
          </a> -->

          <template v-if="!userWorkOnSprint">
            <el-tooltip
              v-if="!enoughCompetenceForJob || isArchieveJob"
              class="box-item"
              effect="customized"
              :content="
                isArchieveJob
                  ? 'Задача является архивной, выполнение недоступно'
                  : 'Чтобы получить доступ, получи необходимые компетенции'
              "
              placement="bottom"
            >
              <button class="button__disabled">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="17"
                  viewBox="0 0 10 17"
                  fill="none"
                >
                  <path
                    d="M5 1.5C6.10457 1.5 7 2.39543 7 3.5V7.5H3V3.5C3 2.39543 3.89543 1.5 5 1.5ZM8 7.5V3.5C8 1.84315 6.65685 0.5 5 0.5C3.34315 0.5 2 1.84315 2 3.5V7.5C0.895431 7.5 0 8.39543 0 9.5V14.5C0 15.6046 0.895431 16.5 2 16.5H8C9.10457 16.5 10 15.6046 10 14.5V9.5C10 8.39543 9.10457 7.5 8 7.5ZM2 8.5H8C8.55228 8.5 9 8.94772 9 9.5V14.5C9 15.0523 8.55228 15.5 8 15.5H2C1.44772 15.5 1 15.0523 1 14.5V9.5C1 8.94772 1.44772 8.5 2 8.5Z"
                    fill="white"
                  />
                </svg>
                Приступить к задаче
              </button>
            </el-tooltip>

            <template v-if="isArchieveJob === false">
              <ButtonGradient
                v-if="
                  (enoughCompetenceForJob && isRepeateable) ||
                    (enoughCompetenceForJob &&
                      !isRepeateable &&
                      isFreeJob &&
                      !jobRequestSended)
                "
                ButtonText="Приступить к задаче"
                @click="showStartJobModal = true"
              />
            </template>
          </template>

          <a
            href="mailto:azaytseva@forus.ru"
            v-if="
              Project.status !== 'moderation' && Project.status !== 'declined'
            "
          >
            <ButtonGradientBorder
              ButtonText="Получить консультацию по задаче"
            />
          </a>
        </template>

        <template v-if="GuestMode == true">
          <ButtonGradientBorder ButtonText="Хочу в проект" />
        </template>
      </div>
    </div>

    <template v-if="isArchieveJob">
      <div class="CardAddButtons">
        <el-tooltip
          class="box-item"
          effect="customized"
          content="Выбрать другую задачу"
          placement="bottom"
        >
          <router-link
            to="/beta/start?skipRegister=true"
            class="LeaveProject"
          ></router-link>
        </el-tooltip>
        <div class="InfoProject">
          <!-- <span class="TelegramButton"></span> -->
          <!-- <span class="InfoButton"></span> -->
        </div>
      </div>
    </template>
    <template v-else>
      <div
        class="CardAddButtons"
        v-if="GuestMode == false && !enoughCompetenceForJob"
      >
        <el-tooltip
          class="box-item"
          effect="customized"
          content="Выбрать другую задачу"
          placement="bottom"
        >
          <router-link
            to="/beta/start?skipRegister=true"
            class="LeaveProject"
          ></router-link>
        </el-tooltip>
        <div class="InfoProject">
          <!-- <span class="TelegramButton"></span> -->
          <!-- <span class="InfoButton"></span> -->
        </div>
      </div>
    </template>

    <el-dialog
      custom-class="MoreProjectInfo"
      v-model="showFullDesc"
      title="Подробное описание"
      width="30%"
    >
      <span
        style="word-wrap: break-word;
  word-break: break-word;"
        class="ql-editor text-wrap"
        v-html="project_data?.desc"
      ></span>
      <span
        v-if="
          Project?.purpose && Project.purpose !== '' && Project.purpose !== null
        "
        class="HDTitle"
      >
        Цель задачи
      </span>
      <span
        v-if="
          Project?.purpose && Project.purpose !== '' && Project.purpose !== null
        "
        class="HDDesc"
        >{{ Project.purpose }}
      </span>
      <span
        v-if="
          Project?.techStack &&
            Project.techStack !== '' &&
            Project.techStack !== null
        "
        class="HDTitle"
      >
        Технологический стек
      </span>
      <span
        v-if="
          Project?.techStack &&
            Project.techStack !== '' &&
            Project.techStack !== null
        "
        class="HDDesc"
        >{{ Project.techStack }}
      </span>
      <span
        v-if="
          Project?.integrations &&
            Project.integrations !== '' &&
            Project.integrations !== null
        "
        class="HDTitle"
      >
        Интеграция: необходимость получения данных из других систем</span
      >
      <span
        v-if="
          Project?.integrations &&
            Project.integrations !== '' &&
            Project.integrations !== null
        "
        class="HDDesc"
        >{{ Project.integrations }}
      </span>
      <span
        v-if="
          Project?.result && Project.result !== '' && Project.result !== null
        "
        class="HDTitle"
      >
        Желаемый результат
      </span>
      <span
        v-if="
          Project?.result && Project.result !== '' && Project.result !== null
        "
        class="HDDesc ql-editor"
        v-html="Project.result"
      >
      </span>
      <span
        v-if="
          Project?.effect && Project.effect !== '' && Project.effect !== null
        "
        class="HDTitle"
      >
        Ожидаемый эффект
      </span>
      <span
        v-if="
          Project?.effect && Project.effect !== '' && Project.effect !== null
        "
        class="HDDesc"
        >{{ Project.effect }}
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showFullDesc = false">Закрыть</el-button>
        </span>
      </template>
    </el-dialog>

    <div
      class="ModalWrapperGlobal noselect"
      v-show="showStartJobModal"
      :key="showStartJobModal"
    >
      <div class="ModalGlobal">
        <div class="Text">
          <div class="Header">
            <span>
              Вы хотите приступить к задаче
            </span>

            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style="cursor: pointer;"
              @click="showStartJobModal = false"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.599253 0.103391C0.349465 0.218662 0.192244 0.382023 0.0857442 0.637023C-0.0149761 0.877988 -0.0151305 1.04187 0.0850738 1.28458C0.140594 1.41921 1.36054 2.67331 4.17395 5.48806L8.18367 9.49969L4.15047 13.5374C-0.195467 17.8883 -0.0581641 17.7336 0.0262511 18.1836C0.0953416 18.552 0.448018 18.9047 0.81638 18.9737C1.26637 19.0582 1.11173 19.1955 5.46241 14.8497L9.5 10.8166L13.5376 14.8497C17.8883 19.1955 17.7336 19.0582 18.1836 18.9737C18.552 18.9047 18.9047 18.552 18.9737 18.1836C19.0582 17.7336 19.1955 17.8883 14.8497 13.5376L10.8166 9.5L14.8497 5.46241C19.1955 1.11173 19.0582 1.26637 18.9737 0.81638C18.9047 0.448018 18.552 0.0953416 18.1836 0.0262511C17.7336 -0.0581641 17.8883 -0.195467 13.5374 4.15047L9.49969 8.18367L5.48806 4.17395C2.67331 1.36054 1.41921 0.140594 1.28458 0.0850738C1.03418 -0.0183296 0.852395 -0.013428 0.599253 0.103391Z"
                fill="white"
              />
            </svg>
          </div>
          <span>
            Очень здорово, что ты хочешь стать исполнителем задачи
            {{ Project.name }}
          </span>
          <span>
            После подтверждения твоего выбора, я передам своей помощнице о твоем
            выборе и она с тобой свяжется, чтобы побеседовать и задать пару
            вопросов :)
          </span>
        </div>

        <div class="Buttons">
          <ButtonGradient
            @click="sendCandidateReq()"
            ButtonText="Я хочу работать над этой задачей"
          />

          <ButtonGradientBorder
            class="CloseLater"
            @click="showStartJobModal = false"
            ButtonText="Я ещё подумаю"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import "./../../../node_modules/@vueup/vue-quill/dist/vue-quill.core.prod.css";
@import "./../../../node_modules/@vueup/vue-quill/dist/vue-quill.snow.prod.css";

.text-wrap {
  font-family: "Montserrat";
}

.shortDesc {
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 4;
  font-size: 13px;
}

.IsWaiting {
  color: #f9dc5c !important;
  background-color: #f9dc5c26 !important;
  border: 1px solid #f9dc5c26 !important;
}
.IsAccepted {
  color: #25ed69 !important;
  background-color: #25ed6926 !important;
  border: 1px solid #25ed6926 !important;
}
.IsDeclined {
  color: #ed254e !important;
  background-color: #ed254e26 !important;
  border: 1px solid #ed254e26 !important;
}
.IsDone {
  color: #d3caffb2 !important;
  background-color: #27235c !important;
  border: 1px solid #27235c !important;
}

.button__disabled {
  display: flex;
  padding: 0.75rem 1.5rem;
  align-items: center;
  gap: 0.625rem;

  transition: 0.3s;

  border-radius: 0.25rem;
  background: #292558;
  opacity: 0.7;

  color: #fff;

  /* text */
  font-family: "Montserrat";
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 1.45313rem */

  border: none;

  width: fit-content;
}

.button__disabled:hover {
  opacity: 1;
}
.HDTitle {
  width: 100%;
  display: flex;
  margin: 20px 0;
  color: #fff;

  /* h3 */
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  word-break: normal;
}
.redBG {
  color: #ea6262 !important ;
  border-color: #ea6262 !important ;
}
.ProjectDirectionNew {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;

  margin-bottom: 20px;
}

#ProfileProjectCard {
  position: relative;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background: rgba(32, 30, 72, 0.7);

  /* shadow */
  box-shadow: 8px 0px 8px rgba(31, 18, 35, 0.15);
  border-radius: var(--o-s-global-border-radius);

  margin-bottom: 40px;
  overflow: hidden;
}

#ProfileProjectCard > .CardContent {
  position: relative;
  width: 632px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 28px 0% 32px 32px;

  flex-shrink: 1;
}
#ProfileProjectCard > .CardAddButtons {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 16px 15px 35px 60px;
}

.LeaveProject {
  position: relative;
  margin-left: auto;
  width: 40px;
  height: 40px;

  transition: 0.3s;

  background-image: url("./../../assets/img/ReDesign/interface_icons/change_project_24px.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;

  background-color: #656bff40;
  border-radius: 100%;
}
.LeaveProject:hover {
  background-color: #656bff4d;
}

.InfoProject {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: 16px;
}
.InfoProject > *:not(:nth-last-child(1)) {
  margin-right: 16px;
}

.TelegramButton,
.InfoButton {
  position: relative;
  display: block;

  width: 40px;
  height: 40px;

  background: #413e74;
  border-radius: 4px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
}

.TelegramButton {
  background-image: url("./../../assets/img/profile/ProfileProjectCard/Telegram.svg");
}
.InfoButton {
  background-image: url("./../../assets/img/profile/ProfileProjectCard/Info.svg");
}

.ComboSpanLeftAlign {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.ComboSpanLeftAlign > * {
  margin-top: auto;
  margin-bottom: auto;
}
.ComboSpanLeftAlign > *:nth-child(1) {
  margin-right: 8px;
}

.ProjectDifficult {
  position: relative;
  margin-left: 3.5px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
}
.ProjectDifficult > span:not(:nth-last-child(1)) {
  margin-right: 3px;
}
.ProjectDifficult > span {
  margin-top: auto;
  margin-bottom: auto;
}

.DifficultPainted {
  position: relative;
  display: block;
  width: 10px;
  height: 20px;
  background-image: url("./../../assets/img/profile/ProfileProjectCard/DifficultIcon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.DifficultEmpty {
  position: relative;
  display: block;
  width: 10px;
  height: 20px;
  background-image: url("./../../assets/img/profile/ProfileProjectCard/DifficultIconPlaceholder.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.ProjectStage {
  position: relative;
  padding: 4px 8px;
  border: 1px solid #12aee3;
  border-radius: 4px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  /* identical to box height */

  color: #12aee3;
}

.ProjectStageTimer {
  padding: 4px 8px;
  border-radius: 4px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: #1d1a42;

  background-color: #12aee3;
}

.ProjectDifficultContainer {
  margin-bottom: 11px;
}

.ProjectStageTimerContainer {
  margin-top: -8px;
  margin-bottom: 12px;
}

.ProjectTitle {
  position: relative;
  margin-bottom: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  /* students/white */
  color: #e8e7ec;
  hyphens: auto;
  word-break: break-all;
}

.ProjectDesc {
  position: relative;

  margin-bottom: 16px;
  height: 70px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 155%;

  overflow: hidden;

  /* or 23px */

  /* students/white 2 */
  color: #c8c3de;
}
.ProjectDesc > span {
  position: relative;
  width: 100%;
}

.ProjectInfoButton {
  cursor: pointer;
  position: relative;
  width: fit-content;
  padding-bottom: 1px;
  margin-bottom: 24px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;

  /* students/white */
  color: #e8e7ec;

  border-bottom: 2px solid rgba(232, 231, 236, 0.15);
}

.ProjectDirection {
  padding: 4px 8px;
  border-radius: 4px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: #e8e7ec;

  border-radius: 4px;
  background: #22214d;

  filter: drop-shadow(00px 00px 4px #00000036);
}

.ProjectDirectionText {
  position: relative;
  margin-right: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 155%;

  /* or 23px */

  /* students/white */
  color: #e8e7ec;
}

.ProjectDirectionsContainer {
  margin-bottom: 16.5px;
}

.ProjectDirections {
  margin-left: 8px;
}
.ProjectDirections > span:not(:nth-last-child(1)) {
  margin-right: 8px;
}

.ProjectTags {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}
.ProjectTag {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-right: 27.5px;
}

.ProjectTag > span:not(:nth-last-child(1)):not(:nth-child(1)) {
  margin-right: 3px;
}
.ProjectTag > span {
  margin-top: auto;
  margin-bottom: auto;
}

.TagText {
  position: relative;
  margin-right: 15.5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 155%;
  color: #e8e7ec;
}

.UnfinishedTasks {
  position: relative;
  height: 47px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 155%;
  text-decoration-line: underline;
  color: #e8e7ec;
}

.ProjectButtons {
  position: relative;
  display: flex;
  flex-direction: row;
}
.ProjectButtons > *:not(:nth-last-child(1)) {
  margin-right: 16px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently*/
}
</style>

<style scoped>
@media (max-width: 480px) {
  /* .ProjectTagHeader > .ProjectDifficult,
  .ProjectCustomer,
  .DirectionsList,
  .ProjectTags {
    display: none !important;
  } */

  .ProjectTagHeader .ProjectStage {
    position: relative;
    width: fit-content;
    font-size: 9px;
    line-height: 120%;
  }

  .ComboSpanLeftAlign {
    width: max-content;
  }

  #ProfileProjectCard {
    height: fit-content !important;
    flex-direction: row;
  }

  #ProfileProjectCard > *:not(.el-overlay) {
    width: 60% !important;
  }

  .ProjectDesc {
    height: fit-content;
  }

  #ProfileProjectCard > .CardContent {
    height: fit-content;

    padding: 19px 0px 16px 16px;
  }

  #ProfileProjectCard > .CardAddButtons {
    flex-direction: row-reverse;
    padding: 8px 8px 16px 8px;
    z-index: 100;
    position: relative;
  }
  #ProfileProjectCard > .CardAddButtons > *:nth-child(2) {
    margin-left: 0%;
  }

  #ProfileProjectCard > .CardContent .ProjectButtons {
    justify-content: flex-start;
    flex-direction: column;
    row-gap: 10px;
    width: max-content;
  }

  .button__disabled {
    font-size: 12px;
  }

  .DirectionsList {
    flex-direction: column;
  }
  .DirectionsList > .ProjectDirections {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;

    margin-top: 4px;
    margin-left: 0px;
  }

  .ProjectTags {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .ProjectTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }

  .ProjectDesc {
    display: none;
  }

  .ProjectInfoButton {
    padding-bottom: 1px;
    margin-bottom: 12px;

    font-size: 12px;
    line-height: 110%;
  }

  .UnfinishedTasks {
    height: fit-content;

    font-size: 12px;
    line-height: 155%;

    margin-bottom: 12px;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  .ProjectTagHeader > .ProjectDifficult,
  .ProjectCustomer,
  .DirectionsList,
  .ProjectTags,
  .UnfinishedTasks {
    display: none !important;
  }

  .ProjectTagHeader .ProjectStage {
    position: relative;
    width: fit-content;
    font-size: 12px;
  }

  #ProfileProjectCard {
    flex-direction: row;
    height: fit-content !important;
  }

  .ProjectDesc {
    height: fit-content;
  }

  #ProfileProjectCard > .CardContent {
    height: fit-content;
    width: fit-content;
    min-width: 0;

    padding: 28px 0px 32px 32px;
  }

  #ProfileProjectCard > .CardAddButtons {
    flex-direction: row-reverse;
    width: fit-content;
    padding: 20px 32px 32px 16px;
  }
  #ProfileProjectCard > .CardAddButtons > *:nth-child(2) {
    margin-left: 0%;
  }

  #ProfileProjectCard > .CardContent .ProjectButtons {
    justify-content: flex-start;
    flex-direction: column;
    row-gap: 10px;
  }

  .button__disabled {
    font-size: 12px;
  }

  .DirectionsList {
    flex-direction: column;
  }
  .DirectionsList > .ProjectDirections {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;

    margin-top: 4px;
    margin-left: 0px;
  }

  .ProjectTags {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .ProjectTagHeader > .ProjectDifficult,
  .ProjectCustomer,
  .DirectionsList,
  .ProjectTags,
  .UnfinishedTasks {
    display: none !important;
  }

  .ProjectTagHeader .ProjectStage {
    position: relative;
    width: fit-content;
    font-size: 12px;
  }

  #ProfileProjectCard {
    flex-direction: row;
    height: fit-content !important;
  }

  .ProjectDesc {
    height: fit-content;
  }

  #ProfileProjectCard > .CardContent {
    height: fit-content;

    padding: 28px 0px 32px 32px;
    width: 100%;
    min-width: 0;
  }

  #ProfileProjectCard > .CardAddButtons {
    flex-direction: row-reverse;
    padding: 20px 32px 32px 16px;
    width: fit-content;
  }
  #ProfileProjectCard > .CardAddButtons > *:nth-child(2) {
    margin-left: 0%;
  }

  #ProfileProjectCard > .CardContent .ProjectButtons {
    justify-content: flex-start;
    flex-direction: column;
    row-gap: 10px;
    width: max-content;
  }

  .button__disabled {
    font-size: 12px;
  }

  .DirectionsList {
    flex-direction: column;
  }
  .DirectionsList > .ProjectDirections {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;

    margin-top: 4px;
    margin-left: 0px;
  }

  .ProjectTags {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .ProjectTagHeader .ProjectStage {
    position: relative;
    width: fit-content;
  }

  #ProfileProjectCard {
    height: fit-content !important;
    flex-direction: row;
  }

  .ProjectDesc {
    height: fit-content;
  }

  #ProfileProjectCard > .CardContent {
    height: fit-content;
    width: 100%;
    padding: 28px 0% 32px 32px;
    min-width: 0;
  }

  #ProfileProjectCard > .CardAddButtons {
    flex-direction: row-reverse;
    width: fit-content;
    padding: 20px 32px 0px 16px;
  }
  #ProfileProjectCard > .CardAddButtons > *:nth-child(2) {
    margin-left: 0%;
  }

  #ProfileProjectCard > .CardContent .ProjectButtons {
    justify-content: flex-start;
    flex-direction: column;
    row-gap: 10px;
    width: max-content;
  }

  .DirectionsList {
    flex-direction: column;
  }
  .DirectionsList > .ProjectDirections {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;

    margin-top: 4px;
    margin-left: 0px;
  }

  .ProjectTags {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  #ProfileProjectCard {
    height: fit-content !important;
    flex-direction: row;
  }

  #ProfileProjectCard > *:not(.el-overlay) {
    width: fit-content;
  }

  .ProjectDesc {
    height: fit-content;
  }

  #ProfileProjectCard > .CardContent {
    height: fit-content;
    width: 100%;
    min-width: 0;
    padding: 28px 0px 32px 32px;
  }

  #ProfileProjectCard > .CardAddButtons {
    padding: 28px 32px 0px 32px;
  }
  #ProfileProjectCard > .CardAddButtons > *:nth-child(2) {
    margin-left: 0%;
  }

  #ProfileProjectCard > .CardContent .ProjectButtons {
    justify-content: flex-start;
    flex-direction: column;
    row-gap: 10px;
  }

  .DirectionsList {
    flex-direction: column;
  }
  .DirectionsList > .ProjectDirections {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;

    margin-top: 4px;
    margin-left: 0px;
  }

  .ProjectTags {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}
@media (min-width: 1600px) {
  #ProfileProjectCard > .CardContent {
    width: 100%;
    flex-shrink: 1;
  }
  #ProfileProjectCard > .CardAddButtons {
    width: fit-content;
    flex-shrink: 0;
    padding: 28px 32px 32px 32px;
  }
}
</style>

<script>
import { getAllDifficulties, setWorker } from "@/api/job.js";
import { findById } from "@/api/user.js";
import { createJobCandidateReq } from "@/api/job_candidate_request.js";

import { mapGetters, mapMutations } from "vuex";

import ButtonGradientBorder from "@/components/CustomElements/ButtonGradientBorder.vue";
import ButtonGradient from "@/components/CustomElements/ButtonGradient.vue";

export default {
  name: "ProfileProjectCard",
  props: {
    Project: {
      type: Object,
      default: () => {
        return {};
      },
    },
    lastSprintDates: {
      type: Object,
      default: () => {
        return null;
      },
    },
    UserLevels: {
      type: Array,
      default: () => {
        return [];
      },
    },
    GuestMode: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    UseAutoWidth: {
      type: Boolean,
      default: () => {
        return true;
      },
    },

    UseExistedDifficults: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    enoughCompetenceForJob: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    ExistedDifficults: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isFreeJob: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    isRepeateable: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    userIsWorker: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    jobRequestSended: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    updatePage: {
      type: Function,
      default: () => {
        return null;
      },
    },
    userWorkOnSprint: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    isArchieveJob: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      project_data: {},
      projLevels: [],

      Difficulties: [],

      showFullDesc: false,
      showStartJobModal: false,
    };
  },
  components: { ButtonGradientBorder, ButtonGradient },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),
    declined() {
      return this.Project?.status === "declined";
    },
    setWidthStyle() {
      if (this.UseAutoWidth == false) {
        return `width: fit-content;`;
      } else {
        return `width: auto;`;
      }
    },
    projectCardStatus() {
      if (!this.userIsWorker) {
        if (
          !this.enoughCompetenceForJob ||
          (this.enoughCompetenceForJob && !this.jobRequestSended)
        ) {
          return { s: "blue", t: "Желаемая задача" };
        }

        if (this.enoughCompetenceForJob && this.jobRequestSended) {
          return { s: "yellow", t: "Заявка ожидает рассмотрения" };
        }

        if (
          this.enoughCompetenceForJob &&
          !this.jobRequestSended &&
          (this.isFreeJob || this.isRepeateable)
        ) {
          return { s: "blue", t: "Доступна подача заявки" };
        }
      } else {
        if (this.lastSprintDates != null) {
          return this.sprintStarted(this.lastSprintDates.start)
            ? !this.sprintStarted(this.lastSprintDates.end)
              ? { s: "yellow", t: "В работе" }
              : { s: "red", t: "Спринт не сдан" }
            : {
                s: "blue",
                t: `Спринт начнётся ${this.getHumanDateWithTime(
                  this.lastSprintDates.start
                )}`,
              };
        }
      }

      return "";
    },
  },
  async created() {
    if (!this.UseExistedDifficults) {
      getAllDifficulties().then((response) => {
        this.Difficulties = response.data;
      });
    } else {
      this.Difficulties = this.ExistedDifficults;
    }

    this.project_data.name = this.Project?.name;
    this.project_data.desc = this.Project?.description;
    if (this.Project?.status !== "moderation")
      this.project_data.directions = this.Project?.sections.map((x) => {
        return { text: x.name, BGColor: "#1dd185" };
      });

    //следующих данных пока нет, поэтому заглушки на будущее
    this.project_data.stage = "";
    this.project_data.stageTimer = "";
    this.project_data.difficult = 0;
    this.project_data.customer = "";
    this.project_data.unfinishedTasks = [];
    this.project_data.Tags = [];
  },
  methods: {
    ...mapMutations("auth", ["SET_USER"]),

    sprintStarted(date_start) {
      if (date_start == null) {
        return false;
      }

      const specifiedDate = new Date(date_start);
      const currentDate = new Date();

      return specifiedDate <= currentDate;
    },
    getHumanDateWithTime(date_end) {
      if (date_end == null || date_end == "") {
        return "";
      }

      let Date = date_end.split("T")[0].split("-");
      let Time = date_end.split("T")[1].split(":");
      return `${Date[2]}.${Date[1]}.${Date[0]} ${Time[0]}:${Time[1]}`;
    },

    async sendCandidateReq() {
      await createJobCandidateReq({
        action: "choose",
        jobID: this.Project.id,
        userID: this.user.id,
      }).then((res) => {
        // console.log(res);
        // findById(this.user.id).then((response) => {
        //   this.SET_USER(response.data);
        // });
        this.showStartJobModal = false;
        this.updatePage();
      });
    },

    cutLongText(Text) {
      if (Text == null) {
        return "";
      }

      if (Text.length >= 130) {
        return `${Text.substr(0, 127)}...`;
      } else {
        return Text;
      }
    },
    backgroundStyle(BGStyle) {
      return `
        background-color: ${BGStyle};
      `;
    },

    getTaskDifficult(DiffName) {
      let indx = this.Difficulties.findIndex((obj) => {
        return obj.name === DiffName;
      });

      return indx + 1;
    },
  },
};
</script>
